<packex-expansion-panel (opened)="detailsOpened()"
                        (closed)="detailsClosed()"
                        [showTitle]="true"
                        [showDescription]="false"
                        [showTopnav]="true"
                        [expanded]="isOpen"
                        [shadowed]="true"
                        [attr.data-testid]="'inventory-item-'+ inventoryItem.id">
  <ng-container topnav>
    <input type="hidden" [value]="inventoryItem.id" [attr.data-testid]="'inventory-item-id'"/>
    <packex-well-notification
        *ngIf="inventoryItemHasError(inventoryItem, 'MATERIAL_NOT_ACTIVE')|| inventoryItemHasError(inventoryItem, 'FIXED_MATERIAL_HAS_CHANGED')"
        icon="warning-primary" [showCloseButton]="false"
        class="mb-4"
        [showNewText]="false">
      {{ 'INVENTORY.LIST.ITEM.MATERIAL_NOT_AVAILABLE' | transloco : {material: inventoryItem.material.name} }}
    </packex-well-notification>

    <div class="d-flex flex-row">
      <div class="col-9 col-lg-8 d-flex align-items-center">
        <mat-checkbox class="me-4 d-none d-xl-block" [checked]="selected"
                      (change)="select($event.checked)"></mat-checkbox>
        <div class="d-none d-md-flex flex-wrap h-100 align-items-center">
          <packex-inventory-label *ngFor="let label of labels"
                                  margin="0.5rem 0.5rem 0.5rem 0"
                                  [passive]="true"
                                  [label]="label"></packex-inventory-label>
          <packex-button type="clear-icon" icon="label" class="align-items-center" *ngIf="!!labels.length"
                         (buttonClick)="editLabels()"></packex-button>

          <packex-inventory-label [label]="{title: 'LABELS.ADD_LABEL' | transloco, active: false}"
                                  (click)="editLabels()"
                                  customClass="labelButton"
                                  class="d-flex"
                                  *ngIf="!labels.length">
            <packex-icon icon="label" class="me-1"></packex-icon>
          </packex-inventory-label>
        </div>
        <div class="d-flex d-md-none flex-wrap h-100 align-items-center">
          <packex-inventory-label margin="0 0.5rem"
                                  [passive]="true"
                                  [label]="labels[0]"></packex-inventory-label>
          <div *ngIf="labels.length > 1" class="label bg-grey-medium me-2">
            +{{ labels.length - 1 }}
          </div>
          <packex-button type="clear-icon" icon="label" class="align-items-center" *ngIf="!!labels.length"
                         (buttonClick)="editLabels()"></packex-button>

          <packex-inventory-label [label]="{title: 'LABELS.ADD_LABEL' | transloco, active: false}"
                                  (click)="editLabels()"
                                  customClass="labelButton"
                                  class="d-flex"
                                  *ngIf="!labels.length">
            <packex-icon icon="label" class="me-1"></packex-icon>
          </packex-inventory-label>
        </div>
      </div>
      <div class="col-3 col-lg-4 d-flex h-100 align-items-center justify-content-end py-1">
        <div class="label subtitle-2 me-3">
            <span
                class="d-none d-lg-inline">{{ 'COMMON.VARIANTS' | transloco: {amount: inventoryItem.variants.length} }}</span>
          <span
              class="d-inline d-lg-none">{{ inventoryItem.variants.length }}</span>
        </div>
        <button [matMenuTriggerFor]="menu">
          <packex-icon icon="kebab"></packex-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [matTooltip]="editProductTooltip | transloco" [disabled]="!canBeEdited" disableRipple
                  (click)="editProduct()">{{ 'INVENTORY.LIST.ITEM.MENU.EDIT' | transloco }}
          </button>
          <button mat-menu-item [disabled]="!inventoryItem" disableRipple
                  (click)="duplicateProduct()">{{ 'INVENTORY.LIST.ITEM.MENU.DUPLICATE' | transloco }}
          </button>
          <button mat-menu-item [disabled]="!inventoryItem" disableRipple
                  (click)="renameProduct()">{{ 'INVENTORY.LIST.ITEM.MENU.RENAME' | transloco }}
          </button>
          <button mat-menu-item (click)="editLabels()"
                  disableRipple>{{ 'INVENTORY.LIST.ITEM.MENU.EDIT_LABELS' | transloco }}
          </button>
          <button mat-menu-item [disabled]="!inventoryItem" disableRipple
                  (click)="deleteInventoryItem()">{{ 'INVENTORY.LIST.ITEM.MENU.DELETE' | transloco }}
          </button>
        </mat-menu>
      </div>
    </div>
  </ng-container>
  <ng-container title>
    <div class="inventory-item-container pt-lg-2 d-flex flex-column flex-xl-row"
         [attr.data-testid]="'inventory-item-name-'+ inventoryItem.name">
      <div class="col-12 col-xl-6 d-flex flex-row">
        <div class="col-3 inventory-item-image-col">
          <packex-inventory-item-dieline-svg
              [inventoryItem]="inventoryItem"
              class="w-100"></packex-inventory-item-dieline-svg>
        </div>
        <div class="col-lg-8 col-9 ms-3">
          <h5 [innerHTML]="inventoryItem.name | highlightSearch: searchTerm" class="text-break"></h5>
          <span
              class="caption text-primary-light">{{ inventoryItem.sku }}</span>
        </div>
      </div>
      <div class="col-12 col-xl-6 d-flex justify-content-end">
        <div class="d-flex pt-4 pt-lg-0 actions-wrapper w-100">
          <div class="button-wrapper d-flex align-items-end">
            <packex-button
                [type]="inventoryItem.variants.length > 0 ? 'secondary' : 'primary'"
                class="mb-2 w-100 w-xl-75"
                buttonPadding="px-4"
                (buttonClick)="addPrintData(inventoryItem)"
                [disabled]="!canAddPrintData"
                [matTooltip]="addPrintDataTooltip | transloco"
                [attr.data-testid]="'inventory-item-add-print-data-button'"
                (click)="$event.stopPropagation()">
              {{ (inventoryItem.variants.length > 0 ? 'INVENTORY.LIST.ITEM.ADD_PRINT_DATA' : 'INVENTORY.LIST.ITEM.DOWNLOAD_DIELINE') | transloco }}
            </packex-button>
            <packex-button
                type="primary"
                class="w-100 w-xl-75"
                buttonPadding="px-4"
                (buttonClick)="addToCart()"
                (click)="$event.stopPropagation()"
                [matTooltip]="orderButtonTooltip | transloco"
                [disabled]="!isOrderable"
                [attr.data-testid]="'inventory-item-add-to-cart-button'"
                icon="cart">
              {{ 'CART.ADD_TO_CART' | transloco }}
            </packex-button>
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="mt-4">
              <packex-button type="clear-icon" [icon]="isOpen?'up':'down'"></packex-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body *ngIf="isOpen">
    <div class="inventory-item-details-container">
      <packex-inventory-list-item-details [variants]="inventoryItem.variants"
                                          [searchTerm]="searchTerm"
                                          [inventoryItem]="inventoryItem"></packex-inventory-list-item-details>
    </div>
  </ng-container>
</packex-expansion-panel>
