import {
  Component,
  EnvironmentInjector,
  Input,
  OnChanges,
} from '@angular/core';
import { formatDate } from '@app/utils/format';
import {
  getTooltipForNotOrderableVariant,
  getVariantName,
  variantHasError,
  variantHasWarning,
  variantInProgress,
  variantIsOrderable,
} from '@app/utils/variant-helper';
import { defaultAmount } from '@modules/cart/modal-cart-item/modal-cart-item.component';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { VariantApproveService } from '@modules/inventory/variant-approve.service';
import { ModalCartItem } from '@shared/models/cart';
import { LABEL, SHIPPING_BOX } from '@shared/models/construction';
import { InventoryItem } from '@shared/models/inventory-item';
import { Variant, VariantStatus } from '@shared/models/variant';
import { orderBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { ConfigurationAttributeValue } from '@shared/models/configuration-attribute-value';

export interface VariantListItem extends Variant {
  position?: number;
}

@Component({
  selector: 'packex-inventory-list-item-details',
  templateUrl: './inventory-list-item-details.component.html',
  styleUrls: ['./inventory-list-item-details.component.scss'],
})
export class InventoryListItemDetailsComponent implements OnChanges {
  @Input() inventoryItem!: InventoryItem;
  @Input() variants!: VariantListItem[];
  @Input() searchTerm?: string;

  printDataHeaders: string[] = ['name', 'status', 'cart'];
  printDataColumns: string[] = ['name', 'status', 'cart'];

  protected readonly getVariantName = getVariantName;
  protected readonly formatDate = formatDate;
  protected readonly getTooltipForNotOrderableVariant =
    getTooltipForNotOrderableVariant;

  constructor(
    private readonly modalCartService: ModalCartService,
    private readonly variantApproveService: VariantApproveService,
    public injector: EnvironmentInjector,
  ) {}

  ngOnChanges() {
    this.variants = orderBy(this.variants, 'updatedAt', 'desc').map(
      (variant: Variant, index: number) => ({
        ...variant,
        position: index + 1,
        orderable: !this.inventoryItem.errors.length
          ? variant.orderable
          : false,
      }),
    );
  }

  get hideGrammage(): boolean {
    return [LABEL, SHIPPING_BOX].includes(
      this.inventoryItem.construction.category,
    );
  }

  public addVariantToCart(variantListItem: VariantListItem): void {
    const modalCartItem: ModalCartItem = {
      ...variantListItem,
      configuration: {
        amount: defaultAmount,
      },
      availableProductOptions: variantListItem.availableProductOptions || [],
      additionalProductionDays: null,
      availableDeliveryMethods: [],
    };

    this.modalCartService.addVariantToCartAndOpenCartModal(this.inventoryItem, [
      modalCartItem,
    ]);
  }

  public openPreflightModal(variant: Variant): void {
    this.variantApproveService.openPreflightModal(variant).subscribe();
  }

  public openInProgressDialog(): void {
    this.variantApproveService.openInProgressDialog();
  }

  public getVariantStatus(variant: Variant): Observable<{
    hasError: boolean;
    hasWarning: boolean;
    inProgress: boolean;
    orderable: boolean;
    status: VariantStatus;
  }> {
    return of({
      hasError: variantHasError(variant),
      hasWarning: variantHasWarning(variant),
      inProgress: variantInProgress(variant),
      status: variant.status,
      orderable: variant.orderable,
    });
  }

  public cartButtonIsDisabled(variant: Variant): boolean {
    return !(
      variantIsOrderable(variant) && this.inventoryItem.errors.length === 0
    );
  }

  public orderButtonTooltip(variant: Variant): string {
    if (this.inventoryItem.errors?.length > 0) {
      switch (this.inventoryItem.errors[0]) {
        case 'FIXED_MATERIAL_HAS_CHANGED':
          return translate('INVENTORY.ADJUST_MATERIAL_TO_ORDER');
        case 'MATERIAL_NOT_ACTIVE':
          return translate('INVENTORY.ADJUST_MATERIAL_TO_ORDER');
        case 'CONSTRUCTION_ARCHIVED':
          return translate('INVENTORY.ADJUST_CONSTRUCTION_TO_ORDER');
        case 'NO_ORDERABLE_VARIANTS':
          return translate('INVENTORY.ADJUST_PRINT_DATA_TO_ORDER');
      }
    }
    return getTooltipForNotOrderableVariant(variant) ?? '';
  }

  public getCoating(): ConfigurationAttributeValue | undefined {
    return this.inventoryItem?.configurationAttributeValues[0];
  }
}
