import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { getPaymentMethodTranslation } from '@app/utils/cart.helper';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CartOrder } from '@shared/models/cart-order';

@UntilDestroy()
@Component({
  selector: 'packex-order-received',
  templateUrl: './order-received.component.html',
  styleUrls: ['./order-received.component.scss'],
})
export class OrderReceivedComponent implements OnInit {
  order?: CartOrder;
  billingEmails: string[] = [];
  deliveryEmails: string[] = [];

  getPaymentMethodTranslation = getPaymentMethodTranslation;

  constructor(
    private readonly cartService: CartService,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(({ id }) => {
      this.cartService
        .getOrder(id)
        .subscribe((order: CartOrder) => (this.order = order));
    });

    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForBillingEmails,
        this.invoiceRecipientEmails,
      )
      .subscribe((emails) => (this.billingEmails = emails));

    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForDeliveryEmails,
        this.statusUpdateEmails,
      )
      .subscribe((emails) => (this.deliveryEmails = emails));
  }

  get invoiceRecipientEmails(): string[] {
    return (this.order?.invoiceRecipientEmails || []).concat(
      this.billingEmails,
    );
  }

  get statusUpdateEmails(): string[] {
    return (this.order?.statusUpdateEmails || []).concat(this.deliveryEmails);
  }
}
