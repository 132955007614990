import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { ROUTES } from '@app/routes';
import { CART_STEPS } from '@modules/cart/cart-routing.module';
import {
  Cart,
  CartItem,
  CartSteps,
  initialCart,
  ModalCartItem,
} from '@shared/models/cart';
import { CartOrder, CartOrderResponse } from '@shared/models/cart-order';
import { DeliveryMethod } from '@shared/models/delivery-method';
import { ProductOption } from '@shared/models/product-option';
import { Variant } from '@shared/models/variant';
import { DrawerService } from '@shared/services/drawer.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { WINDOW } from '@shared/window.token';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  cart$ = new BehaviorSubject<Cart>(initialCart);
  deliveryCountry$ = new BehaviorSubject<string>('');
  saveEmitter$ = new BehaviorSubject<boolean>(false);

  orderCreating$ = new BehaviorSubject<boolean>(false);
  orderCreateError$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiService: ApiService,
    private readonly drawerService: DrawerService,
    private readonly keycloak: KeycloakService,
    private readonly router: Router,
    private readonly gtm: GoogleTagService,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {
    this.loadCart();
  }

  get cart(): Observable<Cart> {
    return this.cart$.asObservable();
  }

  get cartValue(): Cart {
    return this.cart$.getValue();
  }

  get deliveryCountry(): string {
    return this.deliveryCountry$.getValue();
  }

  get isOrderStep() {
    return this.cartValue.step === CartSteps.ORDER;
  }

  get isCheckoutStep() {
    return this.cartValue.step === CartSteps.CART;
  }

  get nextStep(): number {
    const { step } = this.cartValue;
    return step + 1;
    // @TODO maybe keep for later
    // const hasPaymentMethod = this.cartValue.paymentMethod;
    // const hasBillingAddress = this.cartValue.billingAddress;
    // const hasDeliveryAddress = this.cartValue.deliveryAddress;
    //
    // const completed =
    //   hasPaymentMethod && hasBillingAddress && hasDeliveryAddress;
    // const addressCompleted = hasBillingAddress && hasDeliveryAddress;
    //
    // if (completed) {
    //   return CartSteps.ORDER;
    // }
    //
    // if (addressCompleted) {
    //   return CartSteps.PAYMENT;
    // }
    //
    // if (!addressCompleted) {
    //   return CartSteps.ADDRESSES;
    // }
    //
    // return CartSteps.CART;
  }

  public async loadCart(): Promise<void> {
    const isLoggedIn = await this.keycloak.isLoggedIn();

    if (!isLoggedIn) return;

    this.apiService.get('cart').subscribe((cart: Cart) => {
      this.cart$.next({
        ...this.cart$.getValue(),
        ...cart,
      });
      this.deliveryCountry$.next(
        this.cart$.getValue().deliveryAddress?.country.name || '',
      );
    });
  }

  public createCartItem(
    modalCartItems: ModalCartItem[],
    deliveryMethodId: string,
  ) {
    this.apiService
      .post('cart', {
        variants: modalCartItems.map((item: ModalCartItem) => ({
          variantId: item.id,
          amount: item.configuration.amount,
          productOptionIds: item.productOptions?.map((po) => po.id) || [],
        })),
        deliveryMethodId,
      })
      .subscribe(() => {
        this.loadCart();
      });

    this.drawerService.open();
  }

  public addVariantToCart(
    variant: Variant,
    productOptions: ProductOption[],
    deliveryMethodId: string,
    callback?: () => void,
  ): void {
    this.apiService
      .post('cart', {
        variants: [
          {
            variantId: variant.id,
            amount: variant.inventoryItem.amount,
            productOptionIds: productOptions.map((po) => po.id) || [],
          },
        ],
        deliveryMethodId,
      })
      .subscribe(() => {
        if (callback) callback();
        this.loadCart();
      });

    this.drawerService.open();
  }

  public deleteItemsFromCart(items: CartItem[]) {
    this.apiService
      .delete('cart', {
        body: {
          cartItemIds: [...items.map((item) => item.id)],
        },
      })
      .subscribe(() => this.loadCart());
  }

  public getCartItemThumbnail(cartItem: CartItem) {
    return this.apiService.getRaw(
      `${ApiService.apiUrl}/cart/${cartItem.id}/thumbnail`,
      this.apiService.buildHttpOptions({
        contentType: 'application/svg',
        responseType: 'text',
      }),
    );
  }

  public getDeliveryMethods(category?: string): Observable<DeliveryMethod[]> {
    return this.apiService.get(
      'cart/delivery-methods' + (category ? '?category=' + category : ''),
    );
  }

  public updateDeliveryAddress(deliveryAddressId: string) {
    return this.apiService
      .patch('cart', {
        deliveryAddressId,
      })
      .pipe(tap(() => this.loadCart()));
  }

  public updateBillingAddress(billingAddressId: string) {
    return this.apiService
      .patch('cart', {
        billingAddressId,
      })
      .pipe(tap(() => this.loadCart()));
  }

  public proceed() {
    const { step } = this.cartValue;

    switch (step) {
      case 3:
        this.orderCreating$.next(true);

        this.gtm.push('purchase');

        return this.createOrder().subscribe({
          next: (response: CartOrderResponse) => {
            if (response.redirectUrl) {
              this.window.location = response.redirectUrl;
              return;
            }
            const order = response.order as CartOrder;
            this.loadCart();
            this.orderCreating$.next(false);

            return this.router.navigateByUrl(
              `/${ROUTES.CART}/${CART_STEPS['ORDER_RECEIVED']}/${order.id}`,
            );
          },
          error: () => {
            this.orderCreateError$.next(true);
            this.orderCreating$.next(false);
          },
        });
      case 1:
        this.saveEmitter$.next(true);
        return null;
      default:
        return this.update(
          {
            step: this.nextStep,
          },
          false,
        );
    }
  }

  public update(param: Partial<Cart>, persist = true): void | Observable<Cart> {
    this.saveEmitter$.next(false);

    this.cart$.next({
      ...this.cart$.getValue(),
      ...param,
    });

    if (persist) {
      const obs = this.apiService.patch('cart', {
        deliveryAddressId: this.cart$.getValue().deliveryAddress?.id,
        billingAddressId: this.cart$.getValue().billingAddress?.id,
        invoiceRecipientEmails: this.cart$.getValue().invoiceRecipientEmails,
        statusUpdateEmails: this.cart$.getValue().statusUpdateEmails,
        referenceCodes: this.cart$.getValue().referenceCodes,
        whiteLabelShipping: this.cart$.getValue().whiteLabelShipping,
        paymentMethodId: this.cart$.getValue().paymentMethod?.id,
      });

      obs.subscribe(() => {
        this.loadCart();
      });

      return obs;
    }
  }

  public createOrder() {
    return this.apiService.post('cart/order', {});
  }

  public getOrder(id: any) {
    return this.apiService.get(`orders/${id}`);
  }

  public redeemVoucher(voucherCode: string) {
    return this.apiService.post('cart/voucher', {
      voucherCode,
    });
  }

  public deleteVoucher(voucherCode: string) {
    return this.apiService.delete('cart/voucher', {
      body: {
        voucherCode,
      },
    });
  }

  public processPayment(paymentIntentId: string) {
    return this.apiService.get(`orders/payment/${paymentIntentId}/complete`);
  }
}
