<packex-modal (onDismiss)="dismiss()" >
  <h5 class="modal-title">{{ title | transloco }}</h5>

  <form [formGroup]="form" class="modal-body" *ngIf="variant">

    <p class="mb-4" [innerHTML]="description | transloco"></p>

    <ng-container *ngIf="errors.length || warnings.length || infos.length">
      <div class="subtitle-2 mb-4">{{ 'APPROVE_VARIANT.CHECKLIST_TEXT' | transloco }}</div>
      <ul>
        <li *ngFor="let entry of errors" class="subtitle-2 text-error">
          {{ entry }}
        </li>
        <li *ngFor="let entry of warnings" class="subtitle-2 text-warning">
          {{ entry }}
        </li>
        <li *ngFor="let entry of infos" class="subtitle-2 text-info">
          {{ entry }}
        </li>
      </ul>
    </ng-container>

    <packex-well-notification [showCloseButton]="false">
      {{ notification | transloco }}
      <mat-checkbox class="checkbox me-4 d-block my-1" formControlName="po_checked" *ngIf="productOption">
        <div class="d-flex align-content-center">
          <packex-tooltip [text]="productOption.description"
                          class="me-2 d-flex align-items-center"></packex-tooltip>
          <span class="buttons-navigation text-primary-dark">{{ productOption.name }}</span>
          <span class="buttons-navigation text-primary-dark">
            &nbsp;+{{ (productOption.price || 0) | formatPrice }}
          </span>
        </div>
      </mat-checkbox>
    </packex-well-notification>

    <mat-checkbox class="checkbox me-4 mb-1 mt-6" formControlName="notice_accepted" *ngIf="showNoticeCheckbox">
      <div class="d-flex align-content-center">
        <packex-tooltip [text]="'APPROVE_VARIANT.NOTICE_TOOLTIP' | transloco" class="me-2 d-flex align-items-center"></packex-tooltip>
        <span
          class="buttons-navigation text-primary-dark">{{ 'APPROVE_VARIANT.ACCEPT_NOTICE' | transloco }}</span>
      </div>
    </mat-checkbox>
  </form>

  <packex-button type="primary" class="flex-grow-1" [disabled]="!formIsValid"
                 [matTooltip]="tooltip | transloco"
                 [matTooltipDisabled]="formIsValid"
                 (buttonClick)="accept()">{{ 'COMMON.FORWARD' | transloco }}</packex-button>
</packex-modal>
