import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import localeDe from '@angular/common/locales/de';
import {
  APP_INITIALIZER,
  ApplicationRef,
  DoBootstrap,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { CoreModule } from '@app/core/core.module';
import { HttpErrorInterceptor } from '@app/core/interceptors/http-error.interceptor';
import { LanguageService } from '@app/core/services/language.service';
import { TranslocoRootModule } from '@app/transloco-root.module';
import { initializeKeycloak } from '@app/utils/keycloak-helper';
import { environment } from '@env';
import { CartModule } from '@modules/cart/cart.module';
import { CartService } from '@modules/cart/cart.service';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import * as Sentry from '@sentry/angular';
import { OnboardingDialogService } from '@shared/components/onboarding-dialog/onboarding-dialog.service';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { DeactivateGuard } from '@shared/guards/deactivate.guard';
import { AddressService } from '@shared/services/address.service';
import { AppConfigService } from '@shared/services/app-config.service';
import { DrawerService } from '@shared/services/drawer.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import {
  KeycloakAngularModule,
  KeycloakEvent,
  KeycloakEventType,
  KeycloakService,
} from 'keycloak-angular';
import { InViewportModule } from 'ng-in-viewport';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { IMPERSONATE_KEY } from './core/services/api.service';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // App
    CoreModule,
    AppRoutingModule,
    TranslocoRootModule,
    TranslocoMessageFormatModule.forRoot(),
    KeycloakAngularModule,
    BreadcrumbModule,
    CartModule,
    InViewportModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: navigator.language },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [SnackBarService],
    },
    SnackBarService,
    BreadcrumbService,
    DrawerService,
    CartService,
    AddressService,
    AppConfigService,
    OnboardingDialogService,
    DeactivateGuard,
    GoogleTagService,
    LanguageService,
    environment.production
      ? {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
            logErrors: environment.production,
          }),
        }
      : {
          provide: ErrorHandler,
          useValue: {
            handleError: (e: unknown) => console.error(e),
          },
        },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private readonly keycloakService: KeycloakService,
    private googleTagService: GoogleTagService,
  ) {}

  async ngDoBootstrap(appRef: ApplicationRef) {
    try {
      this.checkForImpersonation();

      this.keycloakService.keycloakEvents$.subscribe((e: KeycloakEvent) => {
        if (e.type === KeycloakEventType.OnAuthSuccess) {
          this.googleTagService.push('login');
        }
      });

      await initializeKeycloak(this.keycloakService)();

      if (!environment.production) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window['keycloak'] = this.keycloakService.getKeycloakInstance();
      }

      appRef.bootstrap(AppComponent);
    } catch (error) {
      console.error('[ngDoBootstrap] init Keycloak failed', error);
      await initializeKeycloak(this.keycloakService)();
    }
  }

  private checkForImpersonation(): void {
    const impersonate = window.location.hash.match(
      /impersonate=([A-Za-z0-9-]*)/,
    );

    if (impersonate) {
      sessionStorage.setItem(IMPERSONATE_KEY, impersonate[1]);
    }
  }
}
