import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '@app/core/services/language.service';
import { inventoryItemHasProductOption } from '@app/utils/inventory-items.helper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DownloadCardConfig } from '@shared/components/ui/download-card/download-card.component';
import { LABEL, SHIPPING_BOX } from '@shared/models/construction';
import { InventoryItem } from '@shared/models/inventory-item';
import { BRAILLE, FSC } from '@shared/models/product-option';

export const configs: DownloadCardConfig[] = [
  {
    image: 'assets/images/templates/druckdatenvorlage.png',
    headline: 'INVENTORY.TEMPLATES.PRINT_DATA_TEMPLATE.HEADLINE',
    caption: 'INVENTORY.TEMPLATES.PRINT_DATA_TEMPLATE.CAPTION',
    downloadText: 'INVENTORY.TEMPLATES.PRINT_DATA_TEMPLATE.DOWNLOAD_TEXT',
    type: 'dieline',
  },
  {
    image: 'assets/images/templates/druckleitfaden.png',
    headline: 'INVENTORY.TEMPLATES.PRINT_GUIDELINE.HEADLINE',
    caption: 'INVENTORY.TEMPLATES.PRINT_GUIDELINE.CAPTION',
    downloadText: 'INVENTORY.TEMPLATES.PRINT_GUIDELINE.DOWNLOAD_TEXT',
    type: 'guideline',
  },
];

const FSCDownloadConfig: DownloadCardConfig = {
  image: 'assets/images/templates/druckleitfaden.png',
  headline: 'INVENTORY.TEMPLATES.FSC.HEADLINE',
  caption: 'INVENTORY.TEMPLATES.FSC.CAPTION',
  downloadText: 'INVENTORY.TEMPLATES.PRINT_GUIDELINE.DOWNLOAD_TEXT',
  type: 'guideline',
};

const HIDE_DIELINE_CATEGORIES = [LABEL, SHIPPING_BOX];

@UntilDestroy()
@Component({
  selector: 'packex-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplatesListComponent implements OnInit {
  @Input() inventoryItem!: InventoryItem;
  configs?: DownloadCardConfig[] = [];

  defaultConfigurations = configs;

  constructor(private readonly languageService: LanguageService) {}

  ngOnInit() {
    this.buildConfigs();
  }

  private buildConfigs() {
    this.configs = this.defaultConfigurations
      .map((config: DownloadCardConfig) => {
        switch (config.type) {
          case 'guideline':
            if (
              this.inventoryItem &&
              inventoryItemHasProductOption(this.inventoryItem, FSC)
            ) {
              config = FSCDownloadConfig;
            }

            config.url = this.getGuidelineUrl();

            return config;
          default:
            return config;
        }
      })
      .filter((x) => {
        return !(
          HIDE_DIELINE_CATEGORIES.includes(
            this.inventoryItem.construction.category,
          ) && x.type === 'dieline'
        );
      });
  }

  private getGuidelineUrl(): string {
    const isEnglish = this.languageService.currentLanguage() == 'en';
    const base = 'https://packex2live.blob.core.windows.net/public';
    const guideLinesBaseUrl = isEnglish
      ? `${base}/guides_eng`
      : `${base}/guides`;

    const hasFSC = inventoryItemHasProductOption(this.inventoryItem, FSC);
    const hasBraille = inventoryItemHasProductOption(
      this.inventoryItem,
      BRAILLE,
    );

    if (!isEnglish) {
      if (this.inventoryItem.construction.category === SHIPPING_BOX) {
        return `${guideLinesBaseUrl}/leitfaden_versandkarton.pdf`;
      }

      if (this.inventoryItem.construction.category === LABEL) {
        return `${guideLinesBaseUrl}/leitfaden_etikettenerstellung.pdf`;
      }

      if (hasFSC && hasBraille) {
        return `${guideLinesBaseUrl}/leitfaden_druckdatenerstellung-braille-fsc.zip`;
      } else if (hasBraille) {
        return `${guideLinesBaseUrl}/leitfaden_druckdatenerstellung-braille.zip`;
      } else if (hasFSC) {
        return `${guideLinesBaseUrl}/leitfaden_druckdatenerstellung-fsc.zip`;
      } else {
        return `${guideLinesBaseUrl}/leitfaden_druckdatenerstellung.pdf`;
      }
    } else {
      if (this.inventoryItem.construction.category === SHIPPING_BOX) {
        return `${guideLinesBaseUrl}/guideline_printdata_shippingboxes.pdf`;
      }

      if (this.inventoryItem.construction.category === LABEL) {
        return `${guideLinesBaseUrl}/guidline_printdata_labels.pdf`;
      }

      if (hasFSC && hasBraille) {
        return `${guideLinesBaseUrl}/guideline_printdata-fsc-braille.zip`;
      } else if (hasBraille) {
        return `${guideLinesBaseUrl}/guidline_printdata-braille.pdf`;
      } else if (hasFSC) {
        return `${guideLinesBaseUrl}/guideline_printdata-fsc.zip`;
      } else {
        return `${guideLinesBaseUrl}/guidline_printdata.pdf`;
      }
    }
  }
}
