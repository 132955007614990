import {
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { VariantsService } from '@app/core/services/variants.service';
import { formattedFormat } from '@app/utils/configuration.helper';
import { variantIsOrderable } from '@app/utils/variant-helper';
import { CartService } from '@modules/cart/cart.service';
import { defaultAmount } from '@modules/cart/modal-cart-item/modal-cart-item.component';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { VariantApproveErrorDialogComponent } from '@modules/inventory/components/variant-approve-error-dialog/variant-approve-error-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Sentry from '@sentry/angular';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ModalCartItemSelectOption } from '@shared/components/packex-select/modal-cart-item-select.component';
import { CartItem, initialModalCart, ModalCartItem } from '@shared/models/cart';
import { Configuration } from '@shared/models/configuration';
import { LABEL } from '@shared/models/construction';
import { DeliveryMethod } from '@shared/models/delivery-method';
import { InventoryItem } from '@shared/models/inventory-item';
import { Variant } from '@shared/models/variant';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { isEqual, orderBy } from 'lodash';
import { firstValueFrom, map } from 'rxjs';
import { VariantApproveWarningDialogComponent } from '@modules/inventory/components/variant-approve-warning-dialog/variant-approve-warning-dialog.component';

@UntilDestroy()
@Component({
  selector: 'packex-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartModalComponent
  extends ModalComponent
  implements OnInit, OnDestroy
{
  public variants: Variant[] = [];
  public deliveryMethods: DeliveryMethod[] = [];

  public selectOptions: ModalCartItemSelectOption[] = [];

  public selectDisabled = false;
  public loading = false;
  public cartDisabled = true;

  constructor(
    dialogRef: MatDialogRef<CartModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      inventoryItem: InventoryItem;
      items?: ModalCartItem[];
      useDefaultOptions?: boolean;
    },
    public readonly cartService: CartService,
    public readonly modalCartService: ModalCartService,
    private readonly variantsService: VariantsService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private readonly gtm: GoogleTagService,
  ) {
    super(dialogRef);
  }

  get cartTitle() {
    return this.dialogData.inventoryItem.construction.category === LABEL
      ? 'CART.MODAL.TITLE_LABEL'
      : 'CART.MODAL.TITLE';
  }

  get cartDescription() {
    return this.dialogData.inventoryItem.construction.category === LABEL
      ? 'CART.MODAL.DESCRIPTION_LABEL'
      : 'CART.MODAL.DESCRIPTION';
  }

  async ngOnInit() {
    await this.loadData();

    this.gtm.push('view_cart');
  }

  ngOnDestroy() {
    this.modalCartService.resetCart();
  }

  private async loadData() {
    this.deliveryMethods = await firstValueFrom(
      this.cartService
        .getDeliveryMethods(this.dialogData.inventoryItem.construction.category)
        .pipe(untilDestroyed(this)),
    );

    await this.loadVariants();
    await this.cartService.loadCart();

    this.modalCartService
      .getCartAsObservable()
      .pipe(untilDestroyed(this))
      .subscribe((_) => {
        if (!isEqual(_, initialModalCart)) {
          this.setLoading(_.loading);
          this.updateVariantsSelectBoxWithOptions();
          this.checkCartButtonDisabled();
        }
      });

    if (this.dialogData.items) {
      this.modalCartService.addItemsToCart(
        this.dialogData.items,
        this.dialogData.useDefaultOptions || false,
      );
    }
  }

  private checkCartButtonDisabled() {
    this.cartDisabled =
      !this.modalCartService.cart.items.length ||
      this.loading ||
      this.hasItemsWithInvalidAmount ||
      this.hasItemWithConfigurationErrors;
  }

  private setDefaultDeliveryMethod(reset?: boolean | undefined) {
    const defaultDeliveryMethod = this.deliveryMethods.find((_) => _.default);

    if (
      defaultDeliveryMethod &&
      (reset || this.modalCartService.cart.deliveryMethodId === '')
    ) {
      this.modalCartService.setDeliveryMethodId(defaultDeliveryMethod.id);
    }
  }

  private handleCartItems(): boolean {
    const items =
      this.cartService.cart$
        .getValue()
        .items.filter(
          (item: CartItem) =>
            item.variant.inventoryItem?.id === this.dialogData.inventoryItem.id,
        ) || [];

    if (items.length > 0) {
      // if cart has variant replace variant with local variant as the data from cart doesn't respect availableProductOptions
      const modalCartItems = items
        .map((item: CartItem) => {
          const variant = this.variants.find(
            (variant: Variant) => variant.id === item.variant.id,
          );

          return variant
            ? {
                ...variant,
                configuration: item.configuration,
                productOptions: item.productOptions,
                availableProductOptions: variant.availableProductOptions,
                lastPreflightResult: variant.lastPreflightResult,
              }
            : null;
        })
        .filter((_) => _) as ModalCartItem[];

      this.modalCartService.addItemsToCart(modalCartItems, false, true);
      this.modalCartService.setDeliveryMethodId(items[0].deliveryMethod.id);
      return true;
    } else {
      this.setDefaultDeliveryMethod();
      return false;
    }
  }

  private async loadVariants() {
    this.variants = await firstValueFrom(
      this.variantsService.getVariants(this.dialogData.inventoryItem.id).pipe(
        untilDestroyed(this),
        map((variants: Variant[]) => orderBy(variants, 'updatedAt', 'desc')),
        map((variants: Variant[]) =>
          variants.filter((variant) => variantIsOrderable(variant)),
        ),
      ),
    );

    this.updateVariantsSelectBoxWithOptions();

    // only add the variant if no items in cart or modalCart already
    if (
      !this.handleCartItems() &&
      !this.modalCartService.cart.items.length &&
      this.selectOptions.length === 1
    ) {
      this.addItem(this.selectOptions[0]);
    }
    return;
  }

  /**
   * Fills the select box with all variants except the ones that have already been selected
   * @private
   */
  private updateVariantsSelectBoxWithOptions() {
    const addedVariantIds =
      this.modalCartService.cart.items?.map((item) => item.id) ?? [];

    this.selectOptions = [
      ...this.variants
        .filter((item) => !addedVariantIds.includes(item.id))
        .map((item) => ({
          ...item,
          configuration: { amount: defaultAmount },
          additionalProductionDays: null,
        })),
    ];

    this.selectDisabled = this.selectOptions.length === 0;
  }

  public addToCart(): void {
    if (!this.modalCartService.cart.items.length) return;

    this.dismiss();

    this.gtm.push('add_to_cart');

    this.cartService.createCartItem(
      this.modalCartService.cart.items,
      this.modalCartService.cart.deliveryMethodId,
    );
  }

  /**
   * Shows a dialog with the preflight result and adds the item to the cart if the user confirms
   * @param {Variant} variant The variant to check
   * @param {ModalCartItemSelectOption} item The item to add to the cart
   * @returns {boolean} True if the dialog was shown, false otherwise
   * @private
   */
  private showPreflightResultDialogIfNeeded(
    variant: Variant,
    item: ModalCartItemSelectOption,
  ): boolean {
    if (
      !!variant.lastPreflightResult?.errors.length ||
      !!variant.lastPreflightResult?.warnings.length
    ) {
      const component = variant.lastPreflightResult.errors.length
        ? VariantApproveErrorDialogComponent
        : VariantApproveWarningDialogComponent;

      this.dialog
        .open(component, {
          data: {
            variant,
          },
        })
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.modalCartService.addItemsToCart([item], true);
          }
        });
      return true;
    } else {
      return false;
    }
  }

  /**
   * Adds a variant to the cart and shows a dialog if the variant is not orderable
   * @param {ModalCartItemSelectOption | null} item The item to add to the cart
   */
  public addItem(item: ModalCartItemSelectOption | null): void {
    if (item) {
      if (!item.orderable) {
        this.variantsService
          .getVariant(item.id)
          .pipe(untilDestroyed(this))
          .subscribe((variant) => {
            if (!variant.lastPreflightResult) {
              Sentry.captureException(
                `Variant ${item.id} is not orderable but preflightResult not present yet.`,
              );
              return;
            }

            if (this.showPreflightResultDialogIfNeeded(variant, item)) return;

            this.modalCartService.addItemsToCart([item], true);
          });
      } else {
        this.modalCartService.addItemsToCart([item], true);
      }
    }
  }

  public onDeliveryMethodChanged($event: string): void {
    this.modalCartService.setDeliveryMethodId($event);
  }

  private setLoading(b: boolean) {
    this.zone.run(() => {
      this.loading = b;
      this.cdr.detectChanges();
    });
  }

  public getFormattedFormat(entity: Configuration | InventoryItem): string {
    return formattedFormat(entity);
  }

  get hasItemsWithInvalidAmount(): boolean {
    return Boolean(
      this.modalCartService.cart.items.find((x: ModalCartItem) => {
        return (
          (x.configuration as { amount: number }).amount <
            x.inventoryItem.construction.minimumConfiguration.amount ||
          (x.configuration as { amount: number }).amount >
            x.inventoryItem.construction.maximumConfiguration.amount
        );
      }),
    );
  }

  get hasItemWithConfigurationErrors(): boolean {
    return Boolean(
      this.modalCartService.cart.items.find(
        (item) => (item.configurationError?.fields || [])?.length > 0,
      ),
    );
  }

  public onItemRemoved(): void {
    this.updateVariantsSelectBoxWithOptions();

    setTimeout(() => {
      if (!this.modalCartService.cart.items.length) {
        this.setDefaultDeliveryMethod(true);
      }
    }, 50);
  }

  public getAvailableDeliveryMethods(): DeliveryMethod[] | undefined {
    return this.modalCartService.cart.items
      .flatMap((_) => _.availableDeliveryMethods ?? [])
      .reduce((acc, curr) => {
        const existing = acc.find((_) => _.id === curr.id);
        if (existing) {
          existing.enabled = existing.enabled && curr.enabled;
        } else {
          acc.push(curr);
        }
        return acc;
      }, [] as DeliveryMethod[]);
  }
}
