import { Injectable } from '@angular/core';
import { GoogleEvents } from '@shared/models/google-events';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagService {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dataLayer = window['dataLayer'];

  public async push(event: GoogleEvents, params?: { [k: string]: string }) {
    try {
      this.dataLayer.push({ ...params, event: 'packex.' + event });
    } catch (e) {
      console.error(`error occurred for pushing event: packex.${event}`, e);
    }
  }

  public async setUserId(user_id: string | null) {
    try {
      this.dataLayer.push({ user_id });
    } catch (e) {
      console.error(`error occurred for setting user id: ${user_id}`, e);
    }
  }
}
