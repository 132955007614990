import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { LanguageService } from '@app/core/services/language.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DrawerService } from '@shared/services/drawer.service';
import { TitleService } from '@shared/services/title.service';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { distinctUntilChanged, filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly storage = window.localStorage;

  constructor(
    keycloakService: KeycloakService,
    private readonly drawerService: DrawerService,
    private readonly titleService: TitleService,
    private router: Router,
    private readonly languageService: LanguageService,
    private readonly translocoService: TranslocoService,
  ) {
    keycloakService.keycloakEvents$.subscribe({
      next: async (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          await keycloakService.updateToken(20);
          console.info('token refreshed');
        }
      },
    });
  }

  ngOnInit() {
    this.languageService.languageChanged$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((before, after) => {
          return Boolean(before && after && before !== after);
        }),
      )
      .subscribe(() => {
        window.location.reload();
      });

    this.translocoService.setActiveLang(this.languageService.currentLanguage());

    this.setupTitleListener();
  }

  private setupTitleListener() {
    this.router.events
      .pipe(filter((e) => e instanceof ResolveEnd))
      .subscribe((e) => {
        const event = e as unknown as ResolveEnd;
        const { data } = this.getDeepestChildSnapshot(event.state.root);

        if (data?.['pageTitle']) {
          this.titleService.setTitle(data['pageTitle']);
        }
      });
  }

  get isOverlayVisible(): boolean {
    return this.drawerService.isDrawerVisible;
  }

  private getDeepestChildSnapshot(snapshot: ActivatedRouteSnapshot) {
    let deepestChild = snapshot.firstChild;
    while (deepestChild?.firstChild) {
      deepestChild = deepestChild.firstChild;
    }
    return deepestChild || snapshot;
  }
}
